
.sidenav-category-title {
  margin: 20px 15px 0 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--color-sidenav-category-title);

  .caret-icon {
    display: flex;
    justify-content: center;
    width: 10px;
    margin-right: 5px;
  }

  .manage-reports-btn {
    display: flex;
    visibility: hidden;
    color: var(--color-primary);
    border-radius: 9999px;
    margin-top: 2px;

    svg {
      width: 14px;
    }
  }

  &:hover .manage-reports-btn {
    visibility: visible;
  }
}
