
.chart-card {
  background-color: var(--color-interactive-fill-neutral-low-contrast);
  border-radius: var(--border-radius-sm);
  border: 1.5px solid var(--color-border-secondary);
  padding: 10px;

  &:hover {
    background-color: var(--color-surface-neutral-1);
    border: 1.5px solid var(--color-border-emphasis);
  }
}

.chart-icon {
  width: 40px;
  flex-shrink: 0;

  svg {
    width: 100%;
    height: 100%;
  }
}
