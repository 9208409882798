
.flyover-filters-new {
  background-color: #2e2e2f;
  padding-top: 30px;

  .time-section::v-deep,
  .widgets-section::v-deep,
  .filters-section::v-deep {
    .title {
      font-size: 12px;
    }
  }
  .filters-section .card {
    min-height: 100px;
  }
}

.card {
  background-color: #353535;
  padding: 15px;
  padding-bottom: 5px;
  border-radius: 10px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.export-link {
  text-shadow: none;
  font-size: inherit;
  font-weight: normal;
  color: darken(#fff, 15%);
  &:hover {
    color: darken(#fff, 30%);
    opacity: 1;
  }
  opacity: 1;

  span.text {
    display: none;
    font-size: 85%;
  }
  @media (min-width: 768px) {
    span.text {
      display: inline-block;
    }
  }
}
