
.update-times-container {
  height: 0px;
  .col-xs-12 {
    min-height: 0;
  }
  .handle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
  }
  table.updates {
    margin-top: 5px;

    th,
    td {
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}
