
.sidenav-folder {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidenav-folder-title {
  margin-bottom: 5px;
  padding-left: 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-sidenav-category-title);
}
