
.pill-list {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
}

.shaded {
  opacity: 0.5;
}

.title {
  margin-right: 10px;
}

.title.disabled {
  color: var(--color-text-disabled);
}
.pill-button.disabled {
  background-color: #4d4d4d;
  color: #dadad9;
  cursor: not-allowed;
  &:hover {
    background-color: #4d4d4d;
  }
}
