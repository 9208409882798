
.submenu-title {
  cursor: pointer;
  padding: 5px 15px;
  white-space: nowrap;

  i.fa {
    font-size: 10px;
  }
}
.dropdown {
  display: block;
  &::v-deep {
    .dropdown-menu {
      left: 100%;
      top: 0;
    }
  }
}
