
.insight-chart {
  margin-top: 5px;
}
.chart-title {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.paging {
  margin-right: 10px;
}
