
td,
th {
  &.frequency {
    width: 20%;
  }
  &.default-parameter {
    width: 20%;
  }
  &.actions {
    width: 20%;
    a {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
