
.table-settings-modal {
  display: inline-block;
}

label {
  display: block;
}
table.column-configuration {
  width: 100%;
  td {
    text-align: left;
  }
}
table.total-visibility {
  width: 100%;
  label {
    display: inline-block;
    font-weight: normal;
    margin-right: 10px;
    margin-left: 5px;
  }
}
