
.dropdown-select {
  .btn.default {
    opacity: 0.5;
  }
  .dropdown-items {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    .dropdown-item {
      padding: 7px 10px;
      border-radius: 5px;
      color: #333;
      white-space: nowrap;
      margin: 4px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-top: 0;
      }

      &:hover {
        background-color: #cceeff;
        cursor: pointer;
      }

      .disabled {
        opacity: 0.5;
      }
    }
  }
}
