
.category {
  padding: 10px;

  &__title {
    .icon {
      display: inline-block;
      width: 15px;
    }
  }
  background-color: #eee;
  margin-bottom: 5px;
  border-radius: 5px;
}
.report {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;

  &:hover {
    background-color: #cceeff;
  }
}

.mh-100 {
  min-height: 100px;
}
