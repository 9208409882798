
.item-menu-container {
  color: var(--color-text-primary);
}

.title {
  padding: 5px 15px;
}

.search-control {
  input,
  input:focus,
  input:active {
    border: none;
    color: var(--color-text-primary);
    background-color: var(--color-surface-neutral-1);
    box-shadow: none;
    font-size: 12px;
    &::placeholder {
      color: var(--color-text-variant);
      font-size: 12px;
    }
  }
  .search-icon {
    line-height: 1;
    margin-top: 3px;
    margin-left: 15px;
  }
}
.item-menu {
  margin-top: 4px;
  border-top: 1px solid var(--color-border-secondary);
  min-width: 300px;
  max-height: 376px;
  overflow-y: auto;
  padding-top: 4px;

  .category-header {
    text-transform: uppercase;
    padding: 10px 10px 0px 10px;
    font-size: 12px;
    color: #999999;
  }
}

.item {
  padding: 7px 15px;
  border-radius: 5px;

  &.active,
  &:hover {
    color: var(--color-text-emphasis);

    &.exclude {
      color: var(--color-semantic-negative-text);
    }
  }

  &:hover {
    cursor: pointer;

    .delete-button {
      display: block;
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .delete-button {
    display: none;

    &:hover * {
      font-weight: bold;
    }
  }
}

.wildcard-item {
  display: inline-block;
  padding: 7px 10px;
  margin: 4px;
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    background-color: #4bcdf0;
  }
}

.footer {
  margin-top: 7px;
}
