
.sidenav-report {
  padding: 6px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.2;
  color: var(--color-sidenav-text);

  .icon {
    display: flex;
    justify-content: center;
    height: 16px;
    margin-right: 10px;
    svg {
      max-height: 16px;
      max-width: 16px;
    }
  }

  &:hover:not(.limited) {
    background-color: var(--color-sidenav-report-active-background);
  }

  &.router-link-exact-active {
    background-color: var(--color-sidenav-report-active-background);
    color: var(--color-sidenav-report-active-text);
  }

  &.limited {
    .icon,
    .report-name {
      opacity: 0.5;
    }
  }
}
