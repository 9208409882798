
.filter-category-section {
  color: white;
  padding-bottom: 10px;
  border-bottom: 1px solid #84827d;

  .category-title-row {
    .category-title {
      font-size: 12px;
      text-transform: uppercase;
    }

    &:hover .category-title {
      color: var(--color-text-emphasis-on-dark);
    }
  }

  &.open {
    .category-title {
      color: var(--color-text-emphasis-on-dark);
    }
  }

  .more-btn {
    display: inline-block;
    padding: 10px;
    background-color: #4d4d4d;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  &::v-deep .filter-selector .pill-list {
    margin-bottom: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
