
.newsletter-definition-from-template-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
