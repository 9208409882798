
h3 {
  margin: 20px 0;
}

.table-controls {
  margin-bottom: 10px;
}

table.table {
  margin-bottom: 0;

  th,
  td {
    &:nth-child(2) {
      display: none;
      @media (min-width: 768px) {
        display: table-cell;
      }
    }
  }
}
