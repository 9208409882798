
$grid-float-breakpoint: 992px;

.ai-assistant-component-view {
  &:last-child {
    min-height: calc(100vh - var(--header-height) - var(--sidenav-iconbar-width) - 30px);

    @media screen and (min-width: $grid-float-breakpoint) {
      min-height: calc(100vh - var(--header-height) - 30px);
    }
  }
}
