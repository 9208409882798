<template>
  <div v-if="filters" class="default-parameter-selector">
    <label>
      <input type="checkbox" :checked="modelValue.use_default_parameters" @input="toggleUseDefaultParameters" />{{
        title
      }}
    </label>
    <div class="default-parameter">
      <select
        v-if="modelValue.use_default_parameters"
        :value="modelValue.default_parameter || 'store'"
        @input="updateDefaultParameter"
      >
        <option>{{ $t("actions.select_parameter") }}</option>
        <option v-for="filter in filters" :key="filter.id" :value="filter.id">{{ filter.name }}</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Filter from "../model/filter";
import i18n from "../i18n";

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    title: { default: i18n.t("activerecord.attributes.my_report.use_default_parameters"), type: String },
  },
  emits: ["update:modelValue"],
  computed: {
    filters(): Filter[] {
      return this.$store.state.parameters.filters.all;
    },
  },
  watch: {
    modelValue: [
      {
        handler: "setDefaultParameterUnlessSet",
      },
    ],
  },
  created() {
    this.setDefaultParameterUnlessSet();
  },
  methods: {
    toggleUseDefaultParameters() {
      const { use_default_parameters } = this.modelValue;

      this.$emit("update:modelValue", { ...this.modelValue, use_default_parameters: !use_default_parameters });
    },
    updateDefaultParameter(evt) {
      const default_parameter = evt.target.value;

      this.$emit("update:modelValue", { ...this.modelValue, default_parameter });
    },
    setDefaultParameterUnlessSet() {
      const { use_default_parameters, default_parameter } = this.modelValue;

      if (use_default_parameters && !default_parameter) {
        this.$emit("update:modelValue", { ...this.modelValue, default_parameter: "store" });
      }
    },
  },
});
</script>

<style scoped>
.default-parameter {
  padding-left: 20px;
  padding-top: 5px;
}
</style>
