
$grid-float-breakpoint: 992px;

.legends {
  background-color: var(--color-surface-neutral-1);
  color: var(--color-text-primary);
  padding: 5px 0;
  border-radius: 5px;

  max-width: 100%;
  white-space: nowrap;

  font-weight: 400;
  font-size: 14px;

  @media (min-width: $grid-float-breakpoint) {
    font-size: 13px;
    background-color: transparent;
  }

  .legend-item {
    overflow: hidden;
    padding-right: 20px;

    > *:last-child {
      flex-shrink: 1;
      /* Allow the child to shrink if necessary */
      white-space: nowrap;
      /* Prevent text from wrapping to the next line */
      overflow: hidden;
      /* Hide the overflowing text */
      text-overflow: ellipsis;
      /* Add ellipsis to the overflowing text */
      min-width: 0;
      /* Necessary for some browsers to correctly apply ellipsis */
    }
  }

  .comparisons-section {
    .legend-item {
      margin-top: 5px;
    }
  }

  i.symbol {
    width: 2px;
    height: 18px;
    margin-right: 5px;
    border: none;
    flex-shrink: 0;

    @media (min-width: $grid-float-breakpoint) {
      width: 4px;
    }
  }

  .daterange {
    padding-right: 5px;
  }

  .description {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.span-3 {
    @media (min-width: 768px) {
      .daterange {
        display: none;
      }
    }
  }

  &.span-4 {
    @media (min-width: 768px) and (max-width: 991px) {
      .daterange {
        display: none;
      }
    }
  }
}
