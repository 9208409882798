
#ai-assistant {
  display: none;

  @media screen {
    display: block;
    position: fixed;
    bottom: var(--sidenav-iconbar-width);
    width: 100%;
    height: auto;
    z-index: var(--z-index-ai-assistant);
  }
  @media screen and (min-width: 992px) {
    display: block;
    position: fixed;
    top: var(--header-height);
    bottom: unset;
    right: 0;
    width: var(--ai-assistant-width);
    height: calc(100vh - var(--header-height));
    padding: 30px 15px 15px 15px;
    overflow: hidden;
    transform: translateX(var(--ai-assistant-width));
    transition: all 0.3s;

    &.open {
      transform: translateX(0);
    }
  }

  @media print {
    display: none;
  }
}
