
.category-view {
  cursor: pointer;
  .category-title {
    padding: 5px;
    .category-icon {
      color: var(--color-yellow);
      width: 20px;
    }
  }
  .actions {
    i.fa:not(:last-child) {
      margin-right: 5px;
    }
  }
  .category-reports {
    padding-left: 25px;
  }

  .report {
    padding: 2px 0;
  }
}
