
.ai-chat {
  padding: 10px 15px;

  @media screen and (min-width: 992px) {
    margin: 0 -5px;
    padding: 10px 5px;
  }
}

.messages {
  @media screen and (min-width: 992px) {
    min-height: 100%;
  }
}
