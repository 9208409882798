
$grid-float-breakpoint: 992px;

.overlay {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-sidenav-overlay);

  &.open {
    display: block;

    @media (min-width: 1200px) {
      &.expanded {
        display: none;
      }
    }
  }
}

.sidenav-container {
  background-color: var(--color-sidenav-background);
  color: var(--color-sidenav-text);
  z-index: var(--z-index-sidenav);
  overflow: hidden;

  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;

  height: var(--sidenav-iconbar-width);
  transition: height 0.2s ease 0.1s;

  &.open {
    height: 100%;
    transition: height 0.2s ease 0s;
  }

  .sidenav-iconbar {
    display: flex;
    height: var(--sidenav-iconbar-width);
    width: 100%;
    justify-content: flex-start;
    column-gap: 20px;
    padding: 0 10px;

    .logo-container {
      display: none;
    }

    .trailing-container {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      @media screen and (min-width: $grid-float-breakpoint) {
        flex-direction: column;
      }
    }

    .expand-btn {
      display: none;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      color: white;
      font-size: 30px;
      font-weight: normal;

      svg {
        outline: none;
      }

      &:hover {
        color: var(--color-sidenav-icon-btn-hover-background);
      }

      @media (min-width: 1200px) {
        display: flex;
      }
    }

    .divider {
      display: none;
    }
  }

  .results-container {
    flex: 1;
    overflow: auto;

    .header {
      margin-top: 25px;
      margin-bottom: 20px;
      padding: 0 15px;

      .results-logo-container {
        .zoined-logo {
          color: white;

          .zoined {
            font-size: 28px;
            font-weight: bold;
            line-height: 1;
          }
        }
      }
    }

    .results {
      opacity: 0;
      transition: opacity 0.2s ease 0s;
    }

    &.open .results,
    &.expanded .results {
      opacity: 1;
      transition: opacity 0.2s ease 0.1s;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    top: 0;
    left: 0;
    bottom: 0;
    height: auto;
    width: auto;
    flex-direction: row;
    justify-content: flex-start;

    .sidenav-iconbar {
      flex-direction: column;
      width: var(--sidenav-iconbar-width);
      height: 100%;
      justify-content: flex-start;
      padding: 0;

      .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        margin-bottom: 20px;
      }

      .divider {
        display: block;
        margin: 10px 15px;
        border-bottom: 2px solid var(--color-sidenav-icon-btn-background);
      }
    }

    .results-container {
      width: 0px;
      height: 100%;
      flex: none;
      transition: width 0.2s ease 0.1s;

      &.open {
        width: var(--sidenav-navbar-width);
        transition: width 0.2s ease 0s;
      }
    }
  }

  @media (min-width: 1200px) {
    .results-container {
      &.expanded {
        width: var(--sidenav-navbar-width);
      }
    }
  }

  @media print {
    display: none;
  }
}
