
.snippet-detail {
  .panel-snippet {
    // Space for drilldown button
    padding-top: 50px;
  }
}

.toolbar {
  gap: 5px;
}
