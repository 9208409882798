
.comparison-scenario-form {
  width: 300px;
  padding: 10px;

  .input-group-btn:not(:last-child) {
    .btn {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  .actions {
    margin-top: 15px;
  }
}
