
.drilldown-options {
  position: absolute;
  padding: 10px;
  z-index: 99;
  top: 100%;
  margin-top: 2px;
  background-color: white;
  border: 1px solid white;
  border-radius: var(--dropdown-border-radius);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

  &.right-align {
    right: 0px;
  }
}

.drilldown-wrapper {
  position: relative;
  z-index: 3;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.drilldown-button {
  background-color: transparent;
  border: none;
  float: right;
  outline: 0px !important;
  appearance: none;
  -webkit-appearance: none;
}

.selected-drilldown {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.drilldown-button i {
  font-size: 20px;
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.2);
}

.drilldown-button i:hover {
  color: #4bcdf0;
}

.drilldown-icon {
  margin-left: 5px;
}

.info-icon {
  color: #ccc;
  margin-bottom: 10px;
}

.drilldown-info {
  background-color: #3d3d3d;
  color: #fff;
  position: absolute;
  z-index: 1000;
  padding: 5px;
  transform: translate(-50%);
  border-radius: 4px;
  margin-top: 4px;
  font-size: 12px;
  text-align: center;
}

.selector {
  min-width: 250px;
  margin-bottom: 10px;
}
