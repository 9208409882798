
.time-period-dropdown {
  display: inline-block;

  .title {
    display: inline-block;
    margin-right: 15px;
  }

  .btn.default {
    opacity: 0.5;
  }

  .dropdown-submenu {
    position: relative;
    .dropdown-menu {
      top: 0;
      left: 100%;
      &.open {
        display: block;
      }
      @media (max-width: 767px) {
        position: absolute;
        left: 0;
      }
    }
  }

  .vc-container {
    border: 0;
  }
}
