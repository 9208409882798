
.active-filters {
  overflow-x: scroll;

  .items {
    display: flex;
    gap: 10px;
  }

  .items {
    width: max-content;
    flex-wrap: nowrap;

    @media screen {
      &.align-center {
        justify-content: center;
      }
    }
  }

  @media (min-width: 768px) {
    overflow-x: unset;

    .items {
      width: 100%;
      flex-wrap: wrap;
    }
  }

  @media print {
    overflow-x: unset;

    .items {
      width: 100%;
      flex-wrap: wrap;
    }
  }
}
