
$grid-float-breakpoint: 992px;

.filters-display {
  overflow: hidden;

  .flyover-handle-list {
    background-color: #656566;
    border-radius: 9999px;
    padding: 0px 15px;
    height: 25px;
    color: white;
    font-size: 10px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.fo-handle {
  cursor: pointer;
  border-radius: 9999px;
  height: 30px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  background-color: var(--color-interactive-fill-primary);
  color: var(--color-text-button-primary);
  flex-shrink: 0;
  transition: background-color 0.2s ease;

  @media (min-width: $grid-float-breakpoint) {
    height: 35px;
  }

  &:hover {
    background-color: var(--color-interactive-fill-primary-variant);
    color: var(--color-text-button-primary);
  }

  &:active,
  &.active {
    background-color: var(--color-interactive-fill-primary-variant);
    color: var(--color-text-button-primary);
  }
}
