
.limited-features-toggle {
  padding: 5px 15px;
  white-space: nowrap;
  .pro-text {
    font-weight: bold;
    color: var(--color-yellow);
  }
  i.fa {
    font-size: 10px;
  }
}

.limited-features {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.invisible {
    height: 0;
    overflow: hidden;
  }
}

.get-pro {
  padding: 10px 15px;
}
