
p.chart-error {
  color: #222;
  text-align: center;
  font-size: 1.5em;
  width: 100%;
}

.zoined-chart {
  min-height: 100px;
  height: 100%;
}

.title {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.content {
  height: 100%;
}

.content.paginated {
  height: calc(100% - 30px);
}

.pagination-control {
  height: 30px;
  text-align: center;
  position: relative;
}

.pagination-control .pagination {
  margin: 0;
}

.alert.panel.panel-snippet {
  background: white;
  position: absolute;
  width: 60%;
  right: 20%;
  margin: 80px auto 0;
  z-index: 89;
  box-shadow: 0 3px 10px -5px rgb(0, 0, 0);

  > .panel-body {
    padding: 10px 0px 10px 0px;
  }

  > .panel-footer {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 10px;
    }
  }
}

.drilldown-info {
  margin-top: 10px;
}

.drill-up-button {
  background-color: #393939;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;

  &:active {
    background-color: #666666;
  }
}

.filter-description {
  font-size: 10px;
}
