
.ai-assistant-panel {
  // background-color: var(--color-surface-neutral-1);
  background-color: white;
  width: 100%;
  height: auto;
  max-height: 70vh;
  padding: 5px 15px 15px 15px;
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  box-shadow: 0px -2px 20px 0px rgba(217, 217, 217, 0.5);
  font-size: 12px;

  .drag-handle {
    display: flex;
    justify-content: center;
    padding: 5px;

    .drag-handle-bar {
      width: 40px;
      height: 5px;
      border-radius: 5px;
      background-color: var(--color-border-variant-2);
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .content-container {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.25s ease;

    &.open {
      grid-template-rows: 1fr;
    }

    .content {
      overflow: hidden;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: column;

      overflow: visible;

      .content {
        flex: 1;
        min-height: 0;
        overflow: visible;
        padding-bottom: 15px;
      }
    }
  }

  @media (min-width: 768px) {
    .header-chat,
    .header-history {
      background-image: url("~@assets/images/zed-full.png");
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: right;
    }

    .header-chat {
      justify-content: flex-start;
    }
  }

  button.close-button {
    position: absolute;
    top: 0;
    right: 0;
    transform: translateY(50%) translateX(-30%);
    font-size: 16px;
  }

  @media (min-width: 768px) {
    max-height: 50vh;
  }

  @media (min-width: 992px) {
    height: 100%;
    max-height: unset;
    padding: 15px;
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
