
table.updates {
  width: 360px;
  margin: 0 10px;

  th,
  td {
    &:not(:last-child) {
      padding-right: 10px;
    }
  }
}
