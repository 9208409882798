
$grid-float-breakpoint: 992px;

.sidenav-icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-color: transparent;
  border-style: solid;
  border-left-width: 0px;
  border-right-width: 0px;
  border-top-width: 4px;
  border-bottom-width: 4px;

  &.logo-container {
    display: none;
  }

  i.fa {
    font-size: 20px;
  }

  svg {
    font-size: 20px;
  }

  .content {
    color: var(--color-sidenav-icon-btn-text);
    background-color: var(--color-sidenav-icon-btn-background);
    border: 1.5px solid var(--color-sidenav-icon-btn-border);
    border-radius: 9999px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover:not(.active) {
    .content {
      color: var(--color-sidenav-icon-btn-hover-text);
      background-color: var(--color-sidenav-icon-btn-hover-background);
      border-color: var(--color-sidenav-icon-btn-hover-background);
    }
  }

  &.active {
    .content {
      color: var(--color-sidenav-icon-btn-active-text);
      background-color: var(--color-sidenav-icon-btn-active-background);
      border-color: var(--color-sidenav-icon-btn-active-background);
    }

    border-bottom-color: var(--color-primary);
  }

  @media (min-width: $grid-float-breakpoint) {
    border-top-width: 0px;
    border-bottom-width: 0px;
    height: 30px;
    margin: 13px 0;
    overflow: visible;

    &.logo-container {
      display: flex;
    }

    .content {
      width: 46px;
      height: 46px;
    }

    &.active {
      border-left-width: 4px;
      border-right-width: 4px;
      border-top-width: 0px;
      border-bottom-width: 0px;
      border-bottom-color: transparent;
      border-left-color: var(--color-sidenav-icon-btn-active-background);
    }
  }
}
