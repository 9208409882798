
$grid-float-breakpoint: 992px;

.prompt-field {
  position: relative;

  input[type="text"] {
    border: none;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
    max-width: unset;
    background: var(--color-surface-neutral-2);
    color: var(--color-text-variant);
    font-size: 14px;
    border-radius: 9999px;
    padding-left: 40px;
    padding-right: 30px;

    @media (min-width: $grid-float-breakpoint) {
      padding-left: 12px;
      border-radius: var(--border-radius-sm);
    }

    &:focus,
    &:not(:placeholder-shown) {
      background-color: transparent;
      border: 1px solid var(--color-border-emphasis);
    }
  }

  .zed-image {
    background-image: url("~@assets/images/zed-front.png");
    background-size: cover;
    background-color: var(--color-text-emphasis-primary);
    width: 32px;
    height: 32px;
    position: absolute;
    top: 1px;
    left: 1px;
    border-radius: 9999px;

    @media (min-width: $grid-float-breakpoint) {
      display: none;
    }
  }

  .submit-button {
    position: absolute;
    top: 5px;
    right: 8px;
  }
}
