
#menubar-mobile {
  padding: 10px;
  background-color: white;
  border-bottom: 1px solid var(--color-border-variant-2);
  position: sticky;
  top: var(--header-height);
  z-index: var(--z-index-menubar);

  @media print {
    display: none !important;
  }
}
