
$grid-float-breakpoint: 992px;

.active-filters-badge {
  @media screen {
    max-width: 300px;
  }

  @media print {
    border: 1px solid black;
  }
}

.icon-container {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-md);
  font-size: 16px;
  color: var(--color-text-emphasis);
}

.items-text {
  @media screen {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
