
.feedback-buttons {
  height: 20px;
}

.ask-comment-form {
  width: 100%;
  max-width: 400px;

  input {
    max-width: 100%;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: transparent;
  }
}
