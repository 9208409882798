
$grid-float-breakpoint: 992px;

#menubar {
  position: relative;
  display: none;
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100%;
  z-index: var(--z-index-menubar);
  transform: translateY(-50%);

  @media screen and (min-width: $grid-float-breakpoint) {
    display: flex;
  }
}
