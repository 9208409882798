<template>
  <spinner v-if="loading"></spinner>
  <div v-else>
    <div v-if="alert" class="alert alert-success" role="alert">{{ alertMsg }}</div>
    <div class="row">
      <div class="col-md-7">
        <profileBlock v-bind="{ user, role }" @get-alert="getAlert" @fetch-data="fetchData"></profileBlock>
      </div>
      <div class="col-md-5">
        <reports :reports="reports"></reports>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchData } from "../lib/data/profile";

import spinner from "../components/spinner.vue";
import profileBlock from "./profile-block";
import reports from "./profile-reports";

export default {
  components: {
    spinner,
    profileBlock,
    reports,
  },
  data() {
    return {
      alert: null,
      alertMsg: null,
      user: {},
      role: {},
      reports: [],
      subscriptions: [],
      loading: false,
      company: 0,
    };
  },
  created: function() {
    this.fetchData();
  },
  methods: {
    getAlert: function(msg) {
      this.alert = true;
      this.alertMsg = msg;
    },
    fetchData: function() {
      fetchData("/profile.json")
        .then((res) => {
          this.user = res.user;
          this.role = res.role;
          this.reports = res.reports;
          this.subscriptions = res.subscriptions;
          this.company = res.company;
          this.loading = false;
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
};
</script>

<style scoped>
.btn-primary.save {
  float: right;
}
</style>
