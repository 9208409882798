
.title {
  display: inline-block;
  margin-right: 10px;
}

.metric-range-dropdown {
  min-width: 340px;
}

.filter-form {
  padding: 6px;

  .filter-form-title {
    padding-bottom: 10px;
  }

  .filter-form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }
}
