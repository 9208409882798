
.business-hours-day-selector {
  margin-bottom: 5px;

  &:not(:last-child) {
    margin-right: 15px;
  }
  .title {
    display: inline-block;
    margin-right: 10px;
  }

  .pill-button.shaded {
    opacity: 0.5;
  }

  .add-button {
    padding: 4px 7px !important;
  }
  .day-form-dropdown {
    min-width: 250px;
  }
  .day-form {
    padding: 6px;
  }

  .day-form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }

  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    label {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
