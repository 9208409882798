
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.placeholder {
  @media (min-width: 992px) {
    margin-top: calc(50vh - var(--header-height) - 200px);
    &.animate {
      animation: pulse 1s infinite;
    }
  }
}
