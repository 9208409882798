
label {
  font-weight: normal;
}

.dimension {
  .title {
    display: block;
    float: left;
    margin-top: 7px;
    margin-right: 10px;
  }

  .allow-options {
    display: block;
    margin: 0 0 5px 60px;
  }

  .item-selector {
    display: block;
    margin: 0 0 0 60px;
  }

  .effective-config {
    margin: 0 0 0 60px;
    font-size: 0.8em;
  }
}
