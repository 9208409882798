
.row-wrapper {
  margin-bottom: 30px;

  .component-row {
    page-break-inside: avoid;

    @media print {
      margin-bottom: 45px;
    }

    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 30px;

    .component {
      grid-column-end: span 12;

      @media (min-width: 768px) {
        &.half {
          grid-column-end: span 6;
        }

        &.third {
          grid-column-end: span 4;
        }

        &.quarter {
          grid-column-end: span 3;
        }
      }

      @media print {
        &.half {
          grid-column-end: span 6;
        }

        &.third {
          grid-column-end: span 4;
        }

        &.quarter {
          grid-column-end: span 3;
        }
      }
    }
  }
}
