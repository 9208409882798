
.top-placeholder {
  display: none;

  @media (min-width: 768px) {
    display: grid;
  }

  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-column-gap: 30px;

  .top-placeholder-item {
    grid-column-end: span 12;

    @media (min-width: 768px) {
      &.half {
        grid-column-end: span 6;
      }

      &.third {
        grid-column-end: span 4;
      }

      &.quarter {
        grid-column-end: span 3;
      }
    }
  }

  .top-placeholder-bg {
    height: 20px;
    background-color: white;
    position: relative;

    .corner-rounding-left {
      display: block;
      z-index: 3;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      width: 10px;
      height: 10px;
      background: radial-gradient(circle at top left, transparent 10px, white 10px);
    }

    .corner-rounding-right {
      display: block;
      z-index: 3;
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translateX(100%);
      width: 10px;
      height: 10px;
      background: radial-gradient(circle at top right, transparent 10px, white 10px);
    }
  }
}

.detail-chart-wrapper {
  &::v-deep {
    .detail-chart-panel {
      border-radius: var(--panel-snippet-border-radius);
    }
  }

  &.start::v-deep {
    .detail-chart-panel {
      border-top-left-radius: 0;
    }
  }

  &.end::v-deep {
    .detail-chart-panel {
      border-top-right-radius: 0;
    }
  }
}
