
.dropdown-item {
  padding: 5px 10px;
  margin: 0 5px;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background-color: #f5f5f5;
  }
}
