
.icon-button {
  &.btn-low-contrast {
    &,
    &:focus {
      background-color: var(--color-interactive-fill-neutral-low-contrast);
      color: var(--color-text-variant);
    }

    &:hover {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary);
        color: var(--color-text-button-primary);
      }
    }
  }

  .hover-icon {
    display: none;
  }

  &:hover {
    .default-icon {
      display: none;
    }

    .hover-icon {
      display: block;
    }
  }
}
.dark-bg {
  .icon-button.btn-xs.btn-primary {
    &,
    &:focus {
      background-color: var(--color-interactive-fill-neutral-high-contrast);
      border-color: var(--color-interactive-fill-neutral-high-contrast);
      color: var(--color-text-primary-inverse);
    }

    &:hover {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary-on-dark);
        border-color: var(--color-interactive-fill-primary-on-dark);
        color: var(--color-button-primary-hover-text);
      }
    }

    &:active,
    &.active {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary-variant-on-dark);
        border-color: var(--color-interactive-fill-primary-variant-on-dark);
        color: var(--color-button-primary-active-text);
      }
    }

    &:disabled,
    &.disabled {
      &,
      &:hover,
      &:focus {
        background-color: var(--color-button-primary-disabled-background);
        border-color: var(--color-button-primary-disabled-background);
        color: var(--color-button-primary-disabled-text);
        opacity: 1;
      }
    }
  }
}
