
.basket-filters {
  .title {
    margin-bottom: 15px;
  }

  .view {
    padding-bottom: 15px;
  }
}
