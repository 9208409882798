
.panel-snippet {
  > .panel-heading {
    border-color: transparent;
  }
  > .panel-body {
    button {
      float: right;
    }
  }
}
