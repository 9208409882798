
.navigation {
  display: flex;
  justify-content: flex-end;
  > a:not(:last-child) {
    margin-right: 5px;
  }
  > a:not(:first-child) {
    margin-left: 5px;
  }

  > a.router-link-active {
    color: var(--color-primary);
  }
}
