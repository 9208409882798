
.currency-selector {
  .dropdown-menu {
    min-width: initial;

    li + li {
      border-top: 1px solid #eee;
    }
  }

  .currency-icon {
    font-size: 16px;
  }
}
