<template>
  <spinner v-if="loading"></spinner>
  <div v-else class="panel-snippet">
    <form>
      <div class="panel-body">
        <h4>{{ displayName }}</h4>
        <div class="row">
          <div v-if="user.profile_picture_url" class="col-xs-4">
            <img class="img-responsive" src="user.profile_picture_url" alt="displayName" />
          </div>
          <div :class="span">
            <div class="row">
              <div class="col-sm-6">
                <label>{{ $t("activerecord.attributes.user.email") }}</label>
                <p>{{ user.email }}</p>
              </div>
              <div class="col-sm-6">
                <label>{{ $t("activerecord.attributes.user.language") }}</label>
                <p>{{ user.native_language }}</p>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <label>{{ $t("profile.default_params") }}</label>
                <p>{{ defaultValues }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="panel-footer">
        <a class="btn btn-primary profile" @click="showPasswordModal">{{ $t("profile.change_password") }}</a>
        <a class="btn btn-primary profile" @click="showEmailModal">{{ $t("profile.change_email") }}</a>
        <a class="btn btn-primary profile" @click="showEditModal">
          <i class="fa fa-edit"></i>{{ $t("profile.edit.title") }}
        </a>
      </div>
      <ProfileEditModal ref="editModal" :user="user" v-bind="$attrs" />
      <ProfilePasswordModal ref="changePasswordModal" v-bind="$attrs" />
      <ProfileEmailModal ref="changeEmailModal" v-bind="$attrs" />
    </form>
  </div>
</template>

<script lang="ts">
import spinner from "../components/spinner.vue";
import ProfileEditModal from "./profile-edit-modal.vue";
import ProfilePasswordModal from "./profile-password-modal.vue";
import ProfileEmailModal from "./profile-email-modal.vue";
import _ from "lodash";
import { menuItemKey } from "@/interfaces/menu-item";
import i18n from "../i18n";

export default {
  components: {
    spinner,
    ProfileEditModal,
    ProfilePasswordModal,
    ProfileEmailModal,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    role: { type: Object, default: null },
  },
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  computed: {
    displayName: function() {
      return this.user.first_name && this.user.last_name
        ? `${this.user.first_name} ${this.user.last_name}`
        : this.user.email;
    },
    span: function() {
      return this.user.profile_picture_url ? "col-xs-8" : "col-xs-12";
    },
    groupings() {
      return this.$store.getters.getParameters("grouping");
    },
    defaultValues() {
      const str = _.compact(
        _.map(this.role?.default_parameters, (config, key) => {
          const values = _.values(config)
            .filter(({ enabled }) => enabled)
            .map(({ name, value }) => name || value);

          if (values.length > 0) {
            const grouping = this.groupings.find((grouping) => menuItemKey(grouping) == key);
            return `${grouping?.name}: ${values.join(", ")}`;
          }
        })
      ).join(", ");

      return str.length === 0 ? i18n.t("newsletter.definition.default_parameter_not_set") : str;
    },
  },
  methods: {
    showEditModal() {
      (this.$refs.editModal as typeof ProfileEditModal).show();
    },
    showPasswordModal() {
      (this.$refs.changePasswordModal as typeof ProfilePasswordModal).show();
    },
    showEmailModal() {
      (this.$refs.changeEmailModal as typeof ProfileEmailModal).show();
    },
  },
};
</script>

<style scoped>
i.fa {
  margin-right: 4px;
}

.btn-primary.profile {
  margin-right: 4px;
}
</style>
