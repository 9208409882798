
.limit-selector {
  display: inline-block;
  width: 80px;

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.dropdown-item a {
  &:hover {
    background-color: #cceeff;
    cursor: pointer;
  }
}
