
@keyframes pulse {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  50% {
    transform: scale(0.9) translate(-50%, -50%);
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
.ai-chat-card {
  h1,
  h2,
  h3,
  h4 {
    font-size: 14px;
  }
}
.sparkle-icon.animate {
  animation: pulse 1s infinite;
}
