
$grid-float-breakpoint: 992px;

.report-action-button {
  @media (min-width: $grid-float-breakpoint) {
    button.btn-icon {
      height: 35px;
      width: 35px;
    }
  }
}
