
.row.margin-bottom-fix-50 {
  margin-bottom: 50px;
}

table td .error {
  color: red;
}

.dropzone {
  border: 2px solid #e5e5e5;
  font-family: Arial, sans-serif;
  letter-spacing: 0.2px;
  color: #777;
  background: #fff;
  transition: 0.2s linear;
  padding: 20px;
  min-height: 150px;
}
