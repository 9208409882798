
a.navbar-dropdown-button {
  margin-left: 20px;

  .title {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-black-90);
    white-space: nowrap;
  }
  .subtitle {
    font-size: 10px;
    color: var(--color-muted);
    white-space: nowrap;
  }
  .trailing {
    margin-left: 10px;
    background-color: var(--color-black-90);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    height: 35px;
    width: 35px;

    svg {
      max-width: 20px;
      max-height: 20px;
    }
  }

  &:hover {
    filter: opacity(0.8);
  }
}
