
label {
  display: block;
}
.scheme-label {
  display: inline;
  margin-left: 5px;
  font-weight: normal;
}
.gradient {
  height: 20px;
  background-color: grey;

  &--default {
    background: linear-gradient(to right, #ffffff, #4bcdf0, #ffbe0f, #fa644a);
  }
  &--traffic-lights {
    background: linear-gradient(to right, green, yellow, red);
  }

  &--reverse-traffic-lights {
    background: linear-gradient(to right, red, yellow, green);
  }

  &--violet {
    background: linear-gradient(to right, #ffffff, #5555ff);
  }

  &--neutral {
    background: linear-gradient(to right, #f2f2f2, #67c9e9);
  }
}
