<template>
  <modal id="add-component-modal" v-model="open" :title="title">
    <spinner v-if="loading"> </spinner>
    <div v-else class="row">
      <div class="editor-left-bar">
        <ul class="nav nav-pills nav-stacked">
          <li role="presentation" :class="{ active: tab === 'general' }">
            <a class="left-link" @click="tab = 'general'"
              ><i class="fa fa-bar-chart left-icon"></i>
              <p class="left-icon-text">{{ $t("dashboard_custom.report_editor.general") }}</p></a
            >
          </li>
          <li role="presentation" :class="{ active: tab === 'filters' }">
            <a class="left-link" @click="tab = 'filters'"
              ><i class="fa fa-sliders left-icon"></i>
              <p class="left-icon-text">{{ $t("dashboard_custom.report_editor.filters") }}</p></a
            >
          </li>
          <li v-if="shared" role="presentation" :class="{ active: tab === 'settings' }">
            <a class="left-link" @click="tab = 'settings'">
              <i class="fa fa-cog left-icon"></i>
              <p class="left-icon-text">{{ $t("dashboard_custom.report_editor.settings") }}</p></a
            >
          </li>
        </ul>
      </div>
      <div class="editor-container container">
        <div class="editor-right-bar col-md-6 col-xs-12">
          <div v-show="tab === 'general'" class="tab-content undefined">
            <div class="col-md-12">
              <h4>{{ $t("dashboard_custom.report_editor.name") }}</h4>
              <input
                v-model="component.custom_title"
                class="form-control name-input"
                placeholder="Add name (optional)"
                type="text"
              />
            </div>
            <div class="type-container col-md-12">
              <h4>{{ $t("dashboard_custom.report_editor.component_type") }}</h4>
              <div
                v-for="(comp, type) in availableComponents"
                :key="type"
                class="component type-item"
                :class="{ selected: comp.name == component.name }"
                @click="selectComponentType(type)"
              >
                <div class="chartImg" :class="comp.name.replace(/[^a-zA-Z]/g, '')"></div>
                <p class="type-text">{{ $t("dashboard_custom.report_editor.component_types." + comp.name) }}</p>
              </div>
            </div>
            <div class="col-md-12">
              <h4>{{ $t("dashboard_custom.report_editor.layout") }}</h4>
              <div id="select-layout">
                <div class="btn-group btn-multiple">
                  <label
                    v-if="isSpanEnabled(12)"
                    class="btn btn-primary"
                    :class="{ active: component.span === 12 }"
                    @click="setConfig('span', 12)"
                    >{{ $t("dashboard_custom.report_editor.full") }}
                  </label>
                  <label
                    v-if="isSpanEnabled(6)"
                    class="btn btn-primary"
                    :class="{ active: component.span === 6 }"
                    @click="setConfig('span', 6)"
                    >{{ $t("dashboard_custom.report_editor.half") }} (1/2)</label
                  >
                  <label
                    v-if="isSpanEnabled(4)"
                    class="btn btn-primary"
                    :class="{ active: component.span === 4 }"
                    @click="setConfig('span', 4)"
                    >{{ $t("dashboard_custom.report_editor.third") }} (1/3)</label
                  >
                  <label
                    v-if="isSpanEnabled(3)"
                    class="btn btn-primary"
                    :class="{ active: component.span === 3 }"
                    @click="setConfig('span', 3)"
                    >{{ $t("dashboard_custom.report_editor.quarter") }} (1/4)</label
                  >
                </div>
              </div>
            </div>
            <div class="col-md-12">
              <h4>{{ $t("dashboard_custom.report_editor.configuration") }}</h4>
              <div class="flex-row align-items-center flex-wrap gap-md column-gap-xxl mb-lg">
                <span v-for="widget in generalWidgetConfigs" :key="widget.key">
                  <config-pill-list
                    v-if="widgetComponent(widget) == 'config-pill-list'"
                    :title="widgetTitle(widget)"
                    :radio="!!widget.radio"
                    :draggable="widget.draggable"
                    :config="componentWidgetConfig[widget.key]"
                    :available-items="widgetOptions(widget)"
                    @update="updateWidgetConfig(widget.key, $event)"
                  ></config-pill-list>
                  <filter-dropdown
                    v-if="widgetComponent(widget) == 'filter-dropdown'"
                    :title="widgetTitle(widget)"
                    :available-items="widgetOptions(widget)"
                    :value="componentWidgetConfig[widget.key]"
                    @update="updateWidgetConfig(widget.key, $event)"
                  ></filter-dropdown>
                  <filter-selector
                    v-if="widgetComponent(widget) == 'filter-selector'"
                    :excludable="false"
                    :config="componentWidgetConfig[widget.key]"
                    :filter="widgetOptions(widget)"
                    @update="updateWidgetConfig(widget.key, $event)"
                  ></filter-selector>
                  <zerofill-selector
                    v-if="widgetComponent(widget) == 'zerofill'"
                    :model-value="componentWidgetConfig[widget.key]"
                    @update:model-value="updateWidgetConfig(widget.key, $event)"
                  ></zerofill-selector
                ></span>
              </div>
            </div>
          </div>
          <div v-show="tab === 'filters'" class="tab-content undefined">
            <div class="col-md-12">
              <h4>{{ $t("dashboard_custom.report_editor.filters") }}</h4>
              <div class="flex-row align-items-center flex-wrap gap-md column-gap-xxl mb-lg">
                <time-period-dropdown
                  :available-items="options.time_ranges"
                  :available-series-types="options.seriesTypes"
                  :value="filters.selection"
                  :default="filterConfiguration.selection"
                  @update="updateFilter('selection', $event)"
                ></time-period-dropdown>
                <comparison-pill-list
                  v-if="!forceSelected.comparisons"
                  :comparisons="filters.comparisons"
                  :defaults="filterConfiguration.comparisons"
                  @update="updateFilter('comparisons', $event)"
                ></comparison-pill-list>
                <business-hours-selector
                  :value="filters.limit_to_business_hours"
                  :default="!!filterConfiguration.limit_to_business_hours"
                  @update="updateFilter('limit_to_business_hours', $event)"
                ></business-hours-selector>
              </div>
              <div class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl">
                <div v-for="widget in filterSectionWidgetConfigs" :key="widget.key">
                  <div
                    v-if="widgetComponent(widget) == 'metric-range'"
                    class="flex-row align-items-center flex-wrap gap-lg column-gap-xxl"
                  >
                    <metric-range-selector
                      :items="filters.metric_range || []"
                      @update="updateFilter('metric_range', $event)"
                    ></metric-range-selector>
                    <rangefilter-union-selector
                      v-if="filters.metric_range && filters.metric_range.length >= 2"
                      :model-value="filters.rangefilter_union"
                      @update:model-value="updateFilter('rangefilter_union', $event)"
                    ></rangefilter-union-selector>
                  </div>
                  <like-for-like-selector
                    v-if="widgetComponent(widget) == 'like-for-like'"
                    :disabled="!widgetEnabled(widget)"
                    :config="componentWidgetConfig.like_for_like"
                    @update="updateWidgetConfig('like_for_like', $event)"
                  ></like-for-like-selector>
                </div>
              </div>
              <hr />
              <div class="mb-md">
                <filterset-selector
                  :config="filtersetConfiguration"
                  @update="updateFilterset($event)"
                ></filterset-selector>
              </div>
              <div class="flex-row align-items-center flex-wrap gap-md column-gap-xxl">
                <filter-selector
                  v-for="filter in options.filters"
                  :key="filter.id"
                  :config="filters[filter.id]"
                  :default-config="defaults[filter.id]"
                  :filter="filter"
                  @update="updateFilter(filter.id, $event)"
                ></filter-selector>
              </div>
              <div
                v-if="hasFilterOverrides"
                class="btn btn-primary"
                style="margin-top: 20px"
                @click="restoreFilterDefaults()"
              >
                {{ $t("actions.restore_defaults") }}
              </div>
            </div>
          </div>
          <div v-show="tab === 'settings'" class="tab-content undefined">
            <div v-if="isAdmin" class="col-md-12">
              <h4>{{ $t("dashboard_custom.report_editor.static") }}</h4>
              <div id="select-layout">
                <div class="btn-group btn-multiple">
                  <label
                    class="btn btn-primary"
                    :class="{ active: !!component.static }"
                    @click="setConfig('static', true)"
                    >{{ $t("dashboard_custom.report_editor.yes") }}</label
                  >
                  <label
                    class="btn btn-primary"
                    :class="{ active: !component.static }"
                    @click="setConfig('static', false)"
                    >{{ $t("dashboard_custom.report_editor.no") }}</label
                  >
                </div>
                <div class="selection-info">{{ staticInfoText }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="editor-preview col-md-6 col-xs-12">
          <div v-if="componentReady" class="preview-wrapper">
            <zoined-report-component
              :component="updatedComponent"
              :filter-configuration="filterConfiguration"
              :show-filter-configuration="true"
              :chart-options="{ custom: true }"
              :custom="true"
              :dashboard="true"
              :preview="true"
              :editable="false"
              @component-updated="updateComponent"
            ></zoined-report-component>
          </div>
          <div v-else-if="component.help_key" class="help-block">
            <i class="fa fa-info-circle mr-sm"></i><span>{{ $t("report.help." + component.help_key) }}</span>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <button class="btn btn-default" @click="open = false">{{ $t("actions.cancel") }}</button>
      <button class="btn btn-primary" :disabled="!componentReady" @click="addComponentAndHideModal">
        {{ $t("actions.apply") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { componentFilters, WIDGET_FILTERS } from "../lib/filter-util";
import { Modal } from "uiv";
import I18n from "../i18n";
import _ from "lodash";

import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import TimePeriod from "../filters/time-period.ts";
import spinner from "../components/spinner.vue";
import FilterDropdown from "../components/filter-dropdown";
import comparisonPillList from "../components/comparison-pill-list";
import filterSelector from "../components/filter-selector";
import timePeriodDropdown from "../components/time-period-dropdown";
import businessHoursSelector from "../components/business-hours-selector";
import metricRangeSelector from "../components/metric-range-selector";
import rangefilterUnionSelector from "../components/rangefilter-union-selector.vue";
import likeForLikeSelector from "../components/like-for-like-selector";
import configPillList from "../components/config-pill-list";
import filtersetSelector from "../components/filterset-selector";
import zerofillSelector from "../components/zerofill-selector";
import { availableComponents } from "./component-types";
import { buildComponent } from "./utils";

const widgetFilters = [
  ...WIDGET_FILTERS,
  "variant",
  "limit",
  "limit_y",
  "selected_items",
  "proportions_set",
  "like_for_like",
  "zerofill",
  "trend",
  "show_values",
  "metric_type",
];

export default {
  name: "AddComponentModal",
  components: {
    spinner,
    zoinedReportComponent,
    FilterDropdown,
    Modal,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    metricRangeSelector,
    rangefilterUnionSelector,
    likeForLikeSelector,
    configPillList,
    filtersetSelector,
    zerofillSelector,
  },
  props: {
    addComponent: {
      type: Function,
      required: true,
    },
    setComponent: {
      type: Function,
      required: true,
    },
    filterConfiguration: {
      type: Object,
      required: true,
    },
    shared: Boolean,
  },
  data() {
    return {
      open: false,
      componentWidgetConfig: {},
      filters: {},
      sets: [],
      loading: false,
      snippet: false,
      metrics: null,
      grouping: [],
      selectedMetrics: null,
      selectedMetrics2: null,
      custom_title: null,
      tab: "general",
      component: {
        custom_title: null,
        type: "",
        name: "",
        pagination: true,
        span: 6,
        static: false,
      },
      filterOptions: {},
      isAdmin: window.zoinedContext.isAdmin,
    };
  },
  computed: {
    options() {
      const sort = this.$store.getters.getParameters("sort") || [];
      const seriesTypes = window.zoinedContext?.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
      const groupings = _.clone(this.$store.getters.getParameters("grouping"));
      const metrics = this.$store.state.parameters.metrics.all;
      const snippets = this.$store.state.parameters.metrics.all?.map((item) => ({ ...item, snippet: true }));
      const filters = this.$store.state.parameters.filters.all;
      const time_ranges = this.$store.state.parameters.timePeriods.all;

      return {
        metrics: metrics || [],
        snippets: snippets || [],
        time_ranges: time_ranges || [],
        filters: filters || [],
        sort,
        seriesTypes,
        groupings,
      };
    },
    availableComponents() {
      return _.pickBy(availableComponents, (component) => {
        return _.every(component.required_features, (feature) => window.zoinedContext.features[feature]);
      });
    },
    spanOptions() {
      if (this.component.spanOptions) {
        return this.component.spanOptions;
      } else {
        return [6, 12];
      }
    },
    currentGrouping() {
      return _.map(this.componentWidgetConfig.grouping, ({ enabled }, key) => ({
        key,
        enabled,
      }))
        .filter(({ enabled }) => enabled)
        .map(({ key }) => key)[0];
    },
    groupingFilter() {
      return _.find(this.options.filters, ({ id }) => id == this.currentGrouping);
    },
    limitOptions() {
      return (this.component.limitOptions || [2, 3, 4, 5, 10]).map((key) => ({
        key,
        name: key,
      }));
    },
    groupingPlaceHolder() {
      return I18n.t("chart.drilling_to");
    },
    title() {
      return I18n.t("dashboard_custom.report_editor.component_title_else");
    },
    componentReady() {
      if (!this.component || !this.component.name) {
        return false;
      }

      if (this.component.validate) {
        return this.component.validate(this);
      } else {
        return (
          (!this.widgets.metrics || this.isPresent("metrics")) && (!this.widgets.grouping || this.isPresent("grouping"))
        );
      }
    },
    // Time selection configured in component
    componentSelection() {
      return this.filters.selection;
    },
    componentComparisons() {
      return this.forceSelected.comparisons || this.filters.comparisons;
    },
    effectiveSelection() {
      return this.componentSelection || this.filterConfiguration.selection;
    },
    effectiveComparisons() {
      return this.componentComparisons || this.filterConfiguration.comparisons || [];
    },
    effectiveFilters() {
      // This is used from component-types
      const { currency } = window.zoinedContext.currency;
      return {
        ...componentFilters(this.filterConfiguration, this.updatedComponent),
        currency,
      };
    },
    componentFilterConfiguration() {
      const cfg = {};

      const supportedWidgets = this.widgetConfigs.filter((widget) => this.widgetEnabled(widget)).map(({ key }) => key);

      const componentWidgetConfig = _.pickBy(
        this.componentWidgetConfig,
        (config, key) =>
          (supportedWidgets.includes(key) || (key == "limit" && this.componentConfig.pagination)) && config !== null
      );

      Object.assign(cfg, this.component.defaults, componentWidgetConfig, this.filters);

      const _comparisons = this.forceSelected.comparisons || this.filters.comparisons;

      if (_comparisons) {
        cfg.comparisons = _comparisons.map(({ enabled, ...comparison }) => {
          const comparisonTimePeriod = new TimePeriod({
            selection: this.effectiveSelection,
            comparison: comparison,
          }).getFilterModel();
          return { ...comparison, ...comparisonTimePeriod.comparison, enabled };
        });
      }

      if (!_.isEmpty(this.sets)) {
        cfg.sets = this.sets;
      }

      return cfg;
    },
    updatedComponent() {
      return {
        ...this.component,
        filterConfiguration: {
          ...this.componentFilterConfiguration,
        },
      };
    },
    filtersetConfiguration() {
      return {
        v: 2,
        sets: this.sets,
        filters: this.filters,
      };
    },
    hasFilterOverrides() {
      return !_.isEmpty(this.filters);
    },
    componentConfig() {
      return this.availableComponents[this.component.name] || this.availableComponents[this.component.type];
    },
    widgetConfigs() {
      const configs = (this.componentConfig && this.componentConfig.widgets) || [];
      return configs.map((config) => (_.isString(config) ? { key: config } : config));
    },
    generalWidgetConfigs() {
      return this.widgetConfigs.filter(
        (widget) =>
          this.widgetEnabled(widget) && widget.component != "like-for-like" && widget.component != "metric-range"
      );
    },
    filterSectionWidgetConfigs() {
      return this.widgetConfigs.filter(
        (widget) =>
          this.widgetEnabled(widget) && (widget.component == "like-for-like" || widget.component == "metric-range")
      );
    },
    widgets() {
      return _.fromPairs(this.widgetConfigs.map((config) => [config.key, config]));
    },
    forceSelected() {
      return (this.componentConfig && this.componentConfig.forceSelected) || {};
    },
    defaults() {
      const defaults = _.reduce(
        this.filterConfiguration,
        (result, config, key) => {
          switch (key) {
            case "selection":
            case "comparisons":
              return result;
            default:
              return {
                ...result,
                [key]: _.fromPairs(
                  _.map(config, ({ enabled, exclude, value, name }, key) => [
                    key,
                    {
                      value: key,
                      enabled,
                      exclude: !!exclude,
                      name: name || value || key,
                    },
                  ])
                ),
              };
          }
        },
        {}
      );
      return defaults;
    },
    staticInfoText() {
      const key = this.component.static
        ? "dashboard_custom.report_editor.static_enabled_info"
        : "dashboard_custom.report_editor.static_disabled_info";

      return I18n.t(key);
    },
  },
  watch: {
    currentGrouping(newGrouping, oldGrouping) {
      if (oldGrouping) {
        this.componentWidgetConfig.selected_items = null;
      }
    },
    updatedComponent: {
      deep: true,
      handler(newConfig, oldConfig) {
        if (this.componentConfig.onComponentChanged) {
          this.componentConfig.onComponentChanged(this, newConfig, oldConfig);
        }
      },
    },
  },
  methods: {
    isPresent(key) {
      const config = this.componentWidgetConfig[key];
      return config && !_.isEmpty(_.filter(config, ({ enabled }) => enabled));
    },
    isSpanEnabled(span) {
      return this.spanOptions.includes(span);
    },
    filterKey(key) {
      return this.widgets[key].filterKey || key;
    },
    widgetEnabled(widget) {
      return (
        widget &&
        (widget.enabled === true ||
          _.isUndefined(widget.enabled) ||
          (_.isFunction(widget.enabled) && widget.enabled(this)))
      );
    },
    widgetTitle({ title, key }) {
      return title || I18n.t(`filter.config.${key}`);
    },
    widgetOptions({ options, key }) {
      return (_.isFunction(options) && options(this)) || (!_.isFunction(options) && options) || this.options[key];
    },
    widgetComponent({ component }) {
      return component || "config-pill-list";
    },
    updateComponent(component) {
      const { filterConfiguration, chartOptions, tableConfig, highchartOptions } = component || {};

      if (chartOptions) {
        this.component.chartOptions = chartOptions;
      }

      if (tableConfig) {
        this.component.tableConfig = tableConfig;
      }

      if (highchartOptions) {
        this.component.highchartOptions = highchartOptions;
      }

      if (filterConfiguration) {
        this.filters = _.omit(filterConfiguration, widgetFilters);
        const componentWidgetConfig = _.pick(filterConfiguration, widgetFilters);
        this.componentWidgetConfig = componentWidgetConfig;
      }

      this.highchartOptions = highchartOptions;
    },
    updateFilter(filter, config) {
      this.filters = { ...this.filters, [filter]: config };
    },
    updateFilterset({ sets, filters }) {
      this.sets = sets;
      this.filters = filters;
    },
    updateWidgetConfig(widget, config) {
      this.componentWidgetConfig[widget] = config;
    },
    restoreFilterDefaults() {
      this.filters = {};
    },
    setMetrics(metrics) {
      this.selectedMetrics = [];
      if (metrics && Object.keys(metrics).length > 0) {
        this.selectedMetrics = Object.keys(metrics).map((metric) => {
          return {
            label: metrics[metric]["value"],
            value: metric,
          };
        });
      }
    },
    setGrouping(groupings) {
      this.grouping = null;
      if (groupings && Object.keys(groupings).length > 0) {
        this.grouping = Object.keys(groupings).map((grouping) => {
          return {
            label: groupings[grouping]["value"],
            value: grouping,
          };
        });
      }
    },
    selectComponentType(type) {
      this.component = _.cloneDeep(this.availableComponents[type]);
      this.setWidgetConfigDefaults();
    },
    setConfig(key, value) {
      this.component[key] = value;
    },
    addComponentAndHideModal() {
      if (this.edit >= 0) {
        this.setComponent(this.updatedComponent, this.edit);
      } else {
        this.addComponent(this.updatedComponent);
      }
      this.open = false;
    },
    showAddComponentModal(components, index) {
      this.tab = "general";

      if (components && index >= 0) {
        this.edit = index;
        const component = components[index];
        this.component = buildComponent(component);

        this.filters = _.omit(this.component.filterConfiguration, [...widgetFilters, "sets"]);

        this.sets = this.component.filterConfiguration.sets || [];

        if (this.component.name == "cross_table" || this.component.name == "cross_tab_custom") {
          this.updateComponent(this.component);
        }
      } else {
        this.edit = -1;
        this.filters = {};
        this.sets = [];
        this.selectComponentType("dashboard_snippet");
      }

      this.setWidgetConfigDefaults();

      this.open = true;
    },

    setWidgetConfigDefaults() {
      const defaultMetric = (
        _.find(this.options.metrics, (metric) => metric.id === "sales") || _.first(this.options.metrics)
      ).id;

      const defaultGrouping = (
        _.find(this.options.groupings, (grouping) => grouping.key === "store") || _.first(this.options.groupings)
      ).key;

      const defaultColumnGrouping = (
        _.find(this.options.groupings, (grouping) => grouping.key === "weekday") || _.first(this.options.groupings)
      ).key;

      const defaultWidgetConfig = {
        metrics: { [defaultMetric]: { enabled: true } },
        grouping: { [defaultGrouping]: { enabled: true } },
        column_grouping: { [defaultColumnGrouping]: { enabled: true } },
      };

      const componentWidgetConfig = _.reduce(
        widgetFilters,
        (config, key) => {
          let value;

          if (this.widgets[key]) {
            value =
              this.widgets[key] &&
              (_.get(this.component.filterConfiguration, key) ||
                (_.isFunction(this.widgets[key].default) && this.widgets[key].default(this)) ||
                (!_.isFunction(this.widgets[key].default) && this.widgets[key].default) ||
                defaultWidgetConfig[key]);
          } else if (key == "limit") {
            value = _.get(this.component.filterConfiguration, "limit");
          }

          return value ? { ...config, [key]: value } : config;
        },
        {}
      );

      this.componentWidgetConfig = componentWidgetConfig;
    },
  },
};
</script>

<style>
.modal-open {
  overflow: initial !important;
  padding-right: 0px !important;
}

.modal-backdrop {
  opacity: 0.5;
}

.metric-select-list {
  margin: 10px;
}

.name-input {
  height: 40px !important;
}

.component.type-item {
  width: 135px;
  display: inline-block;
  vertical-align: top;
}

.chartImg {
  background-size: contain;
}

.component.type-item.selected .chartImg {
  /* border: 1px solid #4bcdf0; */
  /* border-radius: 5px;
  background-color: #ddd; */
  border-color: #4bcdf0;
  border-style: solid !important;
}

.type-item.selected p {
  color: #4bcdf0;
}

.type-text {
  text-align: center;
}

#add-component-modal {
  margin: 20px;
  padding-left: 0;
}

#add-component-modal .modal-dialog {
  max-width: 1400px;
  margin: auto;
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: initial !important;
}

#add-component-modal .modal-dialog .modal-content .modal-body {
  padding-bottom: 0;
  padding-top: 0;
}

.editor-container {
  height: calc(100vh - 159px);
  overflow-y: auto;
}

.multiselect__tag {
  background: #4bcdf0;
}

.multiselect__tag-icon:hover {
  background: #4bcdf0;
}
</style>

<style scoped lang="scss">
.component {
  list-style-type: none;
  margin: 5px;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #4bcdf0;
}

.component:hover {
  cursor: pointer;
  color: #4bcdf0;
}

.metric-list {
  position: absolute;
}

.editor-left-bar {
  padding: 0;
  position: absolute;
  height: 100%;
  background-color: #f9f9f9;
  border-right: 1px solid #f3efef;
}

.editor-container {
  width: calc(100% - 80px) !important; // 100%;
  display: block;
  margin-left: 80px;
}

.editor-right-bar,
.editor-preview {
  float: left;
}

.editor-preview {
  position: relative;
  z-index: 0;
  padding-top: 2rem;
}

.left-icon {
  font-size: 24px;
}

.left-icon-text {
  font-size: 12px;
  margin: 0;
}

.left-link {
  border-radius: 0px !important;
  padding: 10px;
  text-align: center;
}

.preview-wrapper .col-sm-6 {
  width: 100% !important;
}

.selection-info {
  font-size: 0.8em;
  color: #777;
}
</style>
