
.navbar-title {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media print {
    font-size: 14pt;
    font-weight: 400;
  }
}
