
a.site {
  cursor: pointer;
  margin-right: 5px;
}
.actions {
  .action {
    display: inline-block;
    margin: 0 10px;
    a {
      cursor: pointer;
    }
    a:not(:hover) {
      color: gray;
    }
  }
}
