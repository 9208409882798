
.dashboard-metric {
  > .row {
    display: flex;

    &.selection-row {
      min-height: 70px;

      .value {
        margin-top: 23px;
        display: inline-flex;
        align-items: center;
      }

      .symbol {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 2px;
        margin-right: 10px;
      }

      &.span-3 {
        @media (min-width: 768px) {
          min-height: 0;

          .value {
            margin-top: 0;
          }
        }
      }

      &.span-4 {
        @media (min-width: 768px) and (max-width: 991px) {
          min-height: 0;

          .value {
            margin-top: 0;
          }
        }
      }
    }

    &.comparison-row {
      min-height: 45px;

      &.span-3 {
        @media (min-width: 768px) {
          min-height: 0;
        }
      }

      &.span-4 {
        @media (min-width: 768px) and (max-width: 991px) {
          min-height: 0;
        }
      }
    }

    .small-values {
      margin-top: 6px;

      @media (min-width: 992px) {
        margin-top: 0;
      }
    }

    .small-value {
      margin-top: 0;
      padding-left: 10px;
      font-size: 12px;
      font-weight: 700;
      white-space: nowrap;
    }

    .g.selection {
      width: 100%;
    }

    .g.comparison {
      padding-top: 0;
      width: 100%;
    }

    > * {
      float: none;
    }

    .col-sm-6:last-child {
      padding-left: 0;
    }
  }
}
