
table {
  th,
  td {
    // Email column
    &:nth-child(3) {
      word-break: break-all;
    }
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      display: none;
      @media (min-width: 768px) {
        display: table-cell;
      }
    }
  }
}
