
.clear-icon {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #ccc;
  z-index: 1;
  pointer-events: auto;
  &:hover {
    color: #333;
  }
}
