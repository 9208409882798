
.pagination {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: 30px;

  > li > a {
    cursor: pointer;
    max-height: 30px;
  }

  > .disabled > a {
    cursor: not-allowed;
  }

  > .disabled.active > a {
    cursor: default;
    &:hover,
    &:focus {
      cursor: default;
    }
  }

  .page-display {
    display: flex;
    align-items: center;

    .current-page {
      input {
        border: none;
        text-align: right;
        padding: 0 2px;
      }
    }
    .slash {
      width: 1em;
    }
    .total-pages {
      text-align: left;
      padding: 0 2px;
    }
  }
}
