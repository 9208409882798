
.scope-selector {
  margin-bottom: 5px;
}
.business-hours-config {
  margin-bottom: 10px;

  &__title {
    font-weight: bold;
    font-size: 1.1em;
  }

  table {
    width: 100%;

    tr {
      th:first-child {
        width: 200px;
      }
      th:last-child {
        width: 100px;
      }
      td:last-child {
        i {
          margin-right: 5px;
        }
      }
    }
  }
}
