
.title {
  display: inline-block;
  margin-right: 10px;
}

form {
  padding: 6px;
  width: 300px;

  .form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }
}
