
.panel-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 16px;

  @media print {
    margin-bottom: 10px;
  }

  > * {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.toolbar {
  flex-grow: 1;
  justify-content: space-between;
}

@media (min-width: 768px) {
  .toolbar {
    flex-grow: 0;
    justify-content: flex-end;
  }
  .panel-title {
    flex-direction: row;
    font-size: 14px;
  }
}

.span-3,
.span-4,
.span-6 {
  .panel-title {
    flex-direction: column;
    gap: 5px;
  }

  .toolbar {
    flex-grow: 1;
    justify-content: space-between;
  }
}

h3 {
  .pull-right {
    .remove {
      i {
        &:hover {
          color: #81e4ff;
          cursor: pointer;
        }
      }
    }
  }
}

.handle {
  &:hover {
    cursor: move;
  }
}

.remove {
  i {
    margin: 0 2px;
  }
}

.report-block {
  @media screen {
    &.full-screen {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding-top: 44px;
      padding-bottom: 50px;
      z-index: 9999;
      background-color: white;

      .panel-snippet {
        height: 100%;
        padding: 0;
        overflow: auto;
        border-radius: 0;

        .panel-heading {
          position: fixed;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 44px;
          padding: 10px;
          z-index: 11000;
          border-bottom: 1px solid var(--color-border-secondary);

          .panel-title {
            visibility: hidden;
          }
        }

        .panel-body {
          padding: 0;
        }
      }

      .panel-snippet .panel-body .snippet {
        // Unset overflow settings to allow sticky position to work
        overflow: unset;

        &::v-deep {
          .zoined-snippet {
            .content {
              overflow: unset;
            }
            table {
              border-collapse: separate;
              border-spacing: 0px;
              margin: 0;

              thead {
                th.sticky-top {
                  position: sticky;
                  background-color: white;
                  z-index: 10001;

                  &.sticky-left {
                    z-index: 10002;
                  }
                }
              }

              tbody {
                td.sticky-left {
                  position: sticky;
                  background-color: white;
                  z-index: 10000;
                }
              }
            }

            .pagination-control {
              position: fixed;
              bottom: 0;
              padding: 10px;
              height: 50px;
              width: 100%;
              background-color: white;
              border-top: 1px solid var(--color-border-secondary);
            }
          }
        }
      }
    }
  }

  &.detail-open {
    .panel-snippet {
      height: 100%;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.panel-snippet {
  display: block; // was "flex"
  flex-direction: column;
  margin: 0;

  .panel-body {
    flex: 1;
  }
}

.is-table {
  .panel-snippet {
    display: block;
  }
}

.filter-configuration {
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .filters {
    font-size: 11px;
  }
}

svg.chevron {
  width: 24px;
  height: auto;
}
