
.custom {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}
.custom-scenario {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}

.custom-calendar {
  margin-top: 10px;
}

.custom-scenario-form {
  margin-top: 15px;

  .input-group-btn:not(:last-child) {
    .btn {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  .actions {
    margin-top: 10px;
  }
}
