
.dashboard-template-editor {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.title {
  margin-bottom: 20px;
}

.buttons {
  margin-top: 15px;
}

.time-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
