
$grid-float-breakpoint: 992px;
.hide-filters-container {
  position: absolute;
  width: 100%;
}
#filters {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--z-index-flyover-filters);
  background-color: var(--color-surface-neutral-6);

  transform: translateX(100%);
  transition: transform 0.3s ease;

  &.open {
    transform: translateX(0);
  }

  @media (min-width: $grid-float-breakpoint) {
    transform: none;
    position: static;
    width: 100%;
    height: auto;
    background-color: transparent;
  }

  .header {
    position: sticky;
    padding: 20px;
    top: 0;
    background-color: var(--color-black-90);
    z-index: 1;
    border-bottom: 1px solid var(--color-border-variant);

    @media screen and (min-width: $grid-float-breakpoint) {
      display: none;
    }
  }

  .body {
    flex: 1;

    overflow-y: auto;
    overflow-x: visible;

    @media screen and (min-width: $grid-float-breakpoint) {
      overflow: visible;
    }
  }
}
