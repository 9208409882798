
$grid-float-breakpoint: 992px;
.report-actions {
  .components-row {
    display: none;
    @media (min-width: $grid-float-breakpoint) {
      display: flex;
      align-items: flex-end;
      gap: 5px;
    }
  }
}
