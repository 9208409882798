
button.btn#ai-assintant-button {
  display: none;

  @media (min-width: 992px) {
    display: flex;
  }

  align-items: center;
  justify-content: center;
  outline: none !important;
  height: 46px;
  width: 46px;
  padding: 0;
  border-radius: 50%;
  border: 0.5px solid var(--color-border-emphasis);
  background-color: var(--color-surface-neutral-1);
  color: var(--color-text-emphasis);
  transition: all 0.3s ease;

  .icon-sparkle {
    font-size: 28px;
  }

  &:hover {
    background-color: var(--color-interactive-fill-primary);
    color: var(--color-text-button-primary);
    border-color: var(--color-interactive-fill-primary);
  }

  &:active {
    background-color: var(--color-interactive-fill-primary-variant);
    color: var(--color-text-button-primary);
    border-color: var(--color-interactive-fill-primary-variant);
  }
}
