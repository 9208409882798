
.sidenav-search-box {
  height: 40px;
  background-color: var(--color-sidenav-search-box-background);
  border: 1.5px solid var(--color-sidenav-search-box-border);
  border-radius: var(--border-radius-sm);
  margin: 0 15px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  input[type="text"] {
    color: var(--color-sidenav-search-box-text);
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
