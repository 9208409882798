
table {
  width: 100%;

  td,
  th {
    &:first-child {
      width: 50%;
    }

    &:not(:first-child) {
      width: 25%;
    }
  }
}

.dropdown-content {
  margin: 0 10px;
}
