
.add-button {
  padding: 4px 7px !important;
}
.condition {
  > *:not(:last-child) {
    margin-right: 5px;
  }
}
.add-condition-form {
  input.value {
    width: 80px;
    display: inline-block;
  }
  .input-group > * {
    margin-right: 10px !important;
  }

  .actions {
    > * {
      margin-right: 5px;
    }
  }
}
