
.share-button {
  outline: 0px !important;
  svg {
    width: 18px;
  }
}
.share-form {
  min-height: 50px;
  padding: 10px 20px;

  input.link-input {
    font-size: 12px;
    max-width: 100%;
  }
}
label.refresh {
  display: flex;
  margin-bottom: 0;
  input {
    margin-top: 0;
    margin-right: 5px;
  }
  font-weight: normal;
}
