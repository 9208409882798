
.language-selector {
  .dropdown-menu {
    min-width: initial;

    li + li {
      border-top: 1px solid #eee;
    }
  }
}
