
#layout-container {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.buttons {
  margin-top: 15px;
}

.show {
  text-align: center;

  a:hover {
    cursor: pointer;
  }
}

.time-filters {
  margin-bottom: 10px;
}

.row h4 {
  margin: 20px 0;
}
