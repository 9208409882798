
.panel {
  border-style: dashed !important;
}

#new-component-button {
  height: 250px;
  display: flex;
  color: #555;

  .button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: #81e4ff;
    }

    .icon {
      font-size: 120px;
      line-height: 1;
    }

    &:first-child {
      border-right: 1px dashed #ddd;
    }
  }
}

.component-row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  grid-column-gap: 30px;
  grid-row-gap: 30px;

  > * {
    grid-column-end: span 12;

    &.half {
      grid-column-end: span 6;
    }

    &.third {
      grid-column-end: span 4;
    }

    &.quarter {
      grid-column-end: span 3;
    }
  }
}
